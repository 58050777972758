<template>
	<div class="objects position-relative">
		<div class="px-20 pt-20 pb-10 mb-20 bg-white border-bottom border-brd-primary position-relative"
			 style="z-index:1000;">
			<div class="page-header">
				<h1 class="page-title" v-text="this.title" />
				<div class="d-flex position-relative">
					<div class="d-flex w-100 text-nowrap-mask scroller scroller-x position-sticky">
						<NavTabs :navItems="this.navTabs"
								 @onClick="(target_id) => (this.resetFilter && this.loadObjects(1, null, target_id))" />
					</div>
				</div>
				<div class="d-flex border border-brd-primary border-start-0 border-end-0 border-bottom-0 pt-3 pb-1">
					<div class="input-group">
						<button id="button-addon1"
								type="button"
								class="btn btn-outline-primary me-2 rounded-3 fw-semi font-semi"
								@click="this.isFilterOpened = !this.isFilterOpened">
							{{ this.filtersCountLabel }}
							<img :src="require('@/assets/icons/arr-sm-down-icon.svg')" class="ms-1 icon-color-primary" alt="Развернуть" width="12" height="12">
						</button>
						<input type="text" class="form-control border-0 px-1 me-2"
							   placeholder="ID или адрес объекта"
							   aria-label="Поиск по ID или адрес объекта"
							   ref="searchInput"
							   @change="(event) => this.search_query = event.target.value.toString()" />
						<button v-if="this.show_reset_filter" id="button-addon3" type="button" class="crm-btn btn btn-outline-secondary bg-light-gray me-2 me-0 border border-brd-primary rounded-1"
								aria-label="Очистить"
								@click="this.resetFilter()">
							<img :src="require('@/assets/icons/close-icon.svg')" alt="Очистить" width="14" height="14">
						</button>
						<button id="button-addon2" type="button" class="crm-btn btn btn-outline-secondary bg-light-gray me-0 border border-brd-primary rounded-1"
								aria-label="Осуществить поиск"
								@click="this.doSearch()">
							<img :src="require('@/assets/icons/search-icon.svg')" alt="Поиск" width="18" height="18">
						</button>
					</div>
				</div>
			</div>

			<Filters :isOpen="this.isFilterOpened"
					 :section="this.section"
					 :filters="this.filters"
					 :options="this.filterOptions"
					 @closeFilter="data => this.closeFilter(data)"
					 @setupFilter="this.filterChange"
					 @applyFilter="this.applyFilter" />
		</div>

		<ObjectsToolbar v-if="this.pagination.total > 0"
						:currentSection="this.section"
						:selectedCount="this.selected_count"
						@checkObjects='this.onClickSelectAll(true)'
						@uncheckObjects='this.onClickSelectAll(false)'
						@archiveObjects='(state) => this.addRemoveObjectsToArchive(state)'
						@transferObjects='this.setObjectsToTransfer'
						@sendObjectsToPDF='this.addObjectsToPDF'
						@clearAllSelected='this.clearAllSelected' />

		<ObjectsList :isAllChecked="this.isAllChecked"
					 :currentSection="this.section"
					 :tagsList="this.tagsList"
					 :isGlobalSearch="true"
					 ref="objectsList"
					 layout="adverts"
					 @selectedItems="(selected) => this.selected = selected"
					 @updateObjectsList="this.loadObjects"
					 @objectsLoaded="this.objectsLoaded" />

		<!--<ObjectCardList
			:cardType="'type_4'"
			:identifier="'objects'"
		/>-->

		<ObjectsToolbar v-if="this.pagination.total > 0"
						:currentSection="this.section"
						:selectedCount="this.selected_count"
						@checkObjects='this.onClickSelectAll(true)'
						@uncheckObjects='this.onClickSelectAll(false)'
						@archiveObjects='(state) => this.addRemoveObjectsToArchive(state)'
						@transferObjects='this.setObjectsToTransfer'
						@sendObjectsToPDF='this.addObjectsToPDF'
						@clearAllSelected='this.clearAllSelected' />

		<Pagination v-if="this.pagination.pages > 1"
					:initialPage="this.pagination.current"
					:itemsList="this.pagination.items"
					:labels="{
						first: false,
						last: false,
						previous: false,
						next: false
					}"
					:pageSize="this.pagination.per_page"
					:disableDefaultStyles="true"
					@changePage="(page) => this.loadObjects(page)"
					:maxPages="3" />

	</div>

	<DelegateObjectModal
		:objectsIds="this.selected"
		@objectsTransfered="this.loadObjects()" />

	<CancelDelegateObjectModal
		@objectsTransferCanceled="this.loadObjects()" />

	<ResultsModal
		id="objects"
		:state="this.resultsModalState.state"
		:title="this.resultsModalState.title"
		:message="this.resultsModalState.message"
	/>

	<RequisitionsModal />

	<CustomFieldsFilterModal @setupFields="this.setCustomFields" />
	<SubmitToVKModal />
	<SubmitComplaintModal />
	<PresentationNav />
	<AdvertPromotionModal />

</template>

<script>
import CommonService from "../services/CommonService";
import ObjectsToolbar from '../components/objects/ObjectsToolbar';
/*import ObjectCardList from '@/components/common/ObjectCardList';*/
import ObjectsList from '@/components/objects/ObjectsList';
import NavTabs from '../components/common/NavTabs';
import Pagination from '../components/common/Pagination';
import Filters from '../components/common/Filters.vue';
import api from "@/api";
import ResultsModal from "@/components/modals/ResultsModal";
import DelegateObjectModal from "@/components/modals/DelegateObjectModal";
import CancelDelegateObjectModal from "@/components/modals/CancelDelegateObjectModal";
import SubmitComplaintModal from "@/components/modals/SubmitComplaintModal";
import SubmitToVKModal from "@/components/modals/SubmitToVKModal";
import CustomFieldsFilterModal from "@/components/modals/CustomFieldsFilterModal";
import PresentationNav from "@/components/common/PresentationNav";
import AdvertPromotionModal from "@/components/modals/AdvertPromotionModal";
import RequisitionsModal from "@/components/modals/RequisitionsModal";

export default {
	name: "adverts",
	props: {
		funnelId: {type: Number, default: 0 }
	},
	data() {
		return {
			title: "Реклама",
			filtersCount: 0,
			filtersCountLabel: 'Фильтр',
			isFilterOpened: false,
			section: 'adverts',
			selected: [],
			navTabs: {
				'adverts': {id: 'adverts', label: "В работе", title: "В работе", count: 0, active: true, href: '#', action: () => console.log('NavTab click!')},
				//'not_in_adverts': {id: 'not_in_adverts', label: "Не в рекламе", title: "Не в рекламе", count: 0, active: false, href: '#', action: () => console.log('NavTab click!')},
			},
			favouredCount: 0,
			filters: {
				operation_type: null, // "sale",
				advertised: {
					zipal: 0,
					free: 0,
					packages: 0,
					avito: 0,
					cian: 0,
					domclick: 0,
					emls: 0,
					jcat: 0,
				}
			},
			search_query: '',
			filterOptions: {
				types: {
					'sale': {
						label: 'Продажа',
						count: 0,
						title: 'Объекты в продаже',
					},
					'rent': {
						label: 'Аренда',
						count: 0,
						title: 'Объекты в аренде',
					},
				}
			},
			pagination: false,
			isAllChecked: false,
			resultsModalState: {
				state: '',
				title: '',
				message: ''
			},
			tagsList: [],
		};
	},
	methods: {
		onClickSelectAll(state) {
			if (typeof state != "undefined") {
				if (state === true) {
					for (const [key, value] of Object.entries(this.$refs.objectsList.objectsItems)) {
						if (!this.selected.includes(value.id)) {
							this.selected.push(value.id);
						}
					}
					this.isAllChecked = true;

					CommonService.log('log', 'onClickSelectAll()::true', this.selected);

				} else {
					for (const [key, value] of Object.entries(this.$refs.objectsList.objectsItems)) {
						if (this.selected.includes(value.id)) {
							let index = this.selected.indexOf(value.id);
							this.selected.splice(index, 1);
						}
					}
					this.isAllChecked = false;

					CommonService.log('log', 'onClickSelectAll()::false', this.selected);

				}
			} else {
				this.isAllChecked = !this.isAllChecked;

				CommonService.log('log', 'onClickSelectAll()', this.selected);
			}

			this.$refs.objectsList.selectedItems = this.selected;

		},
		onClickResetAll() {
			this.selected = [];
			this.$refs.objectsList.selectedItems = this.selected;
			this.isAllChecked = false;

			CommonService.log('log', 'onClickResetAll()', this.selected);
		},
		addRemoveObjectsToArchive(state) {

			this.selected = this.$refs.objectsList.selectedItems;
			CommonService.log('log', 'addRemoveObjectsToArchive()', this.selected);

			if (this.selected.length > 0) {
				api.put('/objects/archived', {
					objects_ids: this.selected,
					current: (state)
				}).then((response) => {
					CommonService.log('debug', 'addRemoveObjectsToArchive()::axios', { response });

					if (response.status == 200 && response.data.success) {

						state = response.data.state;
						if (!state) {
							this.resultsModalState.state = 'success';
							this.resultsModalState.title = 'Ok';
							this.resultsModalState.message = 'Объекты были успешно восстановлены из архива!';
						} else {
							this.resultsModalState.state = 'success';
							this.resultsModalState.title = 'Ok';
							this.resultsModalState.message = 'Объекты были успешно перенесены в архив!';
						}

						setTimeout(this.loadObjects(false, false, this.section, this.filters), 2000);
					}

				}).catch(function (error) {
					this.resultsModalState.state = 'error';

					CommonService.log('error', 'addRemoveObjectsToArchive()::axios', error);

				});
			} else {
				this.resultsModalState.state = 'error';
				this.resultsModalState.title = 'Ошибка';
				this.resultsModalState.message = 'Не выделены объекты для перемещения в архив!';
			}
		},
		setObjectsToTransfer() {

			this.selected = this.$refs.objectsList.selectedItems;
			CommonService.log('log', 'setObjectsToTransfer()', this.selected);

			let modal = CommonService.getModal('delegateObjectModal');
			if (modal)
				modal.show();

		},
		addObjectsToPDF() {

			this.selected = this.$refs.objectsList.selectedItems;
			CommonService.log('log', 'addObjectsToPDF()', this.selected);

			this.$store.commit('setObjectsToPDF', this.selected);
		},
		clearAllSelected() {

			this.selected = []
			this.$refs.objectsList.selectedItems = this.selected;
			CommonService.log('log', 'clearAllSelected()', this.selected);

		},
		filterChange(filters) {

			filters = CommonService.removeEmpty(filters);
			this.filters = filters;

			CommonService.log('log', 'filterChange()', this.filters);

			this.filtersCount = Object.keys(this.filters).length;
		},
		applyFilter(filters) {

			this.isFilterOpened = false;

			filters = CommonService.removeEmpty(filters);

			CommonService.log('log', 'applyFilter()', this.filters);

			this.loadObjects(false, false, this.section, this.filters);
		},
		resetFilter() {

			this.$refs.searchInput.value = null;
			if (Object.keys(this.filters).length > 0) {

				this.filters = {
					operation_type: null, // "sale",
						advertised: {
						zipal: 0,
							free: 0,
							packages: 0,
							avito: 0,
							cian: 0,
							domclick: 0,
							emls: 0,
							jcat: 0,
					}
				};

				this.filtersCount = 0;
				CommonService.log('log', 'resetFilter()', this.filters);

			}
			
			this.loadObjects(false, false, this.section, this.filters);
		},
		doSearch() {
			let query = this.search_query;
			if (!CommonService.isEmpty(query)) {
				CommonService.log('log', 'doSearch()', query);
				this.loadObjects(false, false, this.section, this.filters, this.search_query);
			}
		},
		getObjects(data) {

			if (!this.isEmpty(data.coords))
				this.geo_coords = data.coords;

			if (!this.isEmpty(data.objects)) {

				CommonService.log('log', 'getObjects()', this.filters);

				this.isFilterOpened = false;
				this.$refs.objectsList.loadObjects(1, 10, this.section, this.filters, this.search_query);
			}
		},
		loadObjects(page, per_page, section, filters, search) {

			this.pagination = {};

			if (!page)
				page = 1;

			if (!per_page)
				per_page = 10;

			if (!filters)
				filters = this.filters;

			if (!search)
				search = null;

			if (section && typeof section !== "undefined")
				this.section = section;

			CommonService.log('log', 'loadObjects()', [page, per_page, this.section]);

			this.isFilterOpened = false;
			this.$refs.objectsList.loadObjects(page, per_page, this.section, filters, search, true);
		},
		objectsLoaded(data) {

			if (data.counters) {
				if (typeof (this.navTabs['adverts']) !== "undefined" && typeof (data.counters.adverts) !== "undefined")
					this.navTabs['adverts'].count = data.counters.adverts;

				if (typeof (this.navTabs['not_in_adverts']) !== "undefined" && typeof (data.counters.not_in_adverts) !== "undefined")
					this.navTabs['not_in_adverts'].count = data.counters.not_in_adverts;

				if (typeof (this.filterOptions.types['sale']) !== "undefined" && typeof (data.counters.sale) !== "undefined")
					this.filterOptions.types['sale'].count = data.counters.sale;

				if (typeof (this.filterOptions.types['rent']) !== "undefined" && typeof (data.counters.rent) !== "undefined")
					this.filterOptions.types['rent'].count = data.counters.rent;

				// Счетчики кол-ва объектов на модерации
				if (this.user_can.moderate_adverts) {

					if (typeof (this.navTabs['approved']) !== "undefined" && typeof (data.counters.is_moderated) !== "undefined")
						this.navTabs['approved'].count = data.counters.is_moderated;

					if (typeof (this.navTabs['moderate']) !== "undefined" && typeof (data.counters.not_moderated) !== "undefined")
						this.navTabs['moderate'].count = data.counters.not_moderated;

				}
			}

			for (const [key, tab] of Object.entries(this.navTabs)) {
				tab['active'] = false;
			}

			if (this.navTabs[this.section])
				this.navTabs[this.section]['active'] = true;

			if (data.pagination) {

				this.pagination = data.pagination;

				CommonService.log('log', 'objectsLoaded()::pagination', this.pagination);

			}

		},
		isEmpty(data) {
			return CommonService.isEmpty(data);
		},
		getTags() {
			return api.get('/common/tags').then((response) => {

				CommonService.log('debug', 'getTags()::axios', {response: response.data});

				if (response.status == 200 && response.data.success) {
					let list = [];
					Object.entries(response.data.list).forEach(([key, option]) => {
						list.push({
							id: option.id,
							name: option.name,
							isChecked: false
						});
					});

					return list;
				} else {
					return {};
				}

			}).catch(function (error) {

				CommonService.log('error', 'getTags()::axios', error);

			});
		},
		setCustomFields(fields) {

			CommonService.log('log', 'setCustomFields()', fields);

			this.filters['custom_fields'] = fields;
		},
		closeFilter(clear) {
			this.isFilterOpened = false;

			if (clear) {
				this.resetFilter();
			}
		},
	},
	components: {
		RequisitionsModal,
		AdvertPromotionModal,
		PresentationNav,
		CustomFieldsFilterModal,
		SubmitToVKModal,
		SubmitComplaintModal,
		DelegateObjectModal,
		CancelDelegateObjectModal,
		ResultsModal,
		ObjectsToolbar,
		ObjectsList,
		/*ObjectCardList,*/
		NavTabs,
		Filters,
		Pagination,
	},
	mounted() {
		CommonService.setPageTitle(this.title);

		if (typeof this.$props.funnelId !== "undefined")
			this.filters.funnel_id = Number(this.$props.funnelId);

		if (this.filtersCount > 0)
			this.filtersCountLabel = CommonService.declOfNum(this.filtersCount, ['опция', 'опции', 'опций'], true);

		this.selected = [];
		this.selected.length = 0;

		if (!this.isEmpty(this.$store.getters.tagsList)) {
			let list = [];
			Object.entries(this.$store.getters.tagsList).forEach(([key, option]) => {
				list.push({
					id: option.value,
					name: option.name,
					isChecked: false
				});
			});
			this.tagsList = list;
		} else {
			this.getTags().then(data => {
				this.tagsList = data;
			});
		}

		this.isAllChecked = false;
		this.$refs.objectsList.loadObjects(1, 10, this.section, this.filters);

		this.emitter.on('closeFilter', () => this.resetFilter());

		if (this.user_can.moderate_adverts) {
			this.navTabs = {...this.navTabs, ...{
					'approved': {
						id: 'approved',
						label: "Подтверждено",
						title: "Подтверждено",
						count: 0,
						active: false,
						href: '#',
						action: () => console.log('NavTab click!')
					},
					'moderate': {
						id: 'moderate',
						label: "На модерации",
						title: "На модерации",
						count: 0,
						active: false,
						href: '#',
						action: () => console.log('NavTab click!')
					}
				}
			};
		} else {
			this.navTabs = {...this.navTabs, ...{
					'not_in_adverts': {
						id: 'not_in_adverts',
						label: "Не в рекламе",
						title: "Не в рекламе",
						count: 0,
						active: false,
						href: '#',
						action: () => console.log('NavTab click!')
					},
				}
			};
		}
	},
	computed: {
		show_reset_filter() {
			return !this.isEmpty(this.search_query);
		},
		user_can() {
			return {
				//add_to_adverts: this.$store.getters.userInfo.user_can.add_to_adverts, // В рекламу
				//add_to_moderation: this.$store.getters.userInfo.user_can.add_to_moderation, // Отправка рекламы на модерацию
				moderate_adverts: this.$store.getters.userInfo.user_can.adverts_moderate, // Модерация объекта в рекламе
			};
		},
	},
	watch: {
		'selected': function(val, oldVal) {
			CommonService.log('debug', 'watch:selected', {value: val, old_value: oldVal});
		},
		filtersCount: function (value) {
			if (value > 0) {
				this.filtersCountLabel = CommonService.declOfNum(value, ['опция', 'опции', 'опций'], true);
			} else {
				this.filtersCountLabel = 'Фильтр';
			}
		}
	}
};
</script>